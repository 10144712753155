import {
  ComponentRef,
  Directive,
  Injector,
  OnDestroy,
  OnInit,
  Type,
  ViewContainerRef,
  effect,
  inject,
  isDevMode,
  reflectComponentType,
  untracked,
  input,
} from '@angular/core';
import { CCASequenceStep, SequenceSummaryValue } from '../sequence';
import { SequenceStore } from '../sequence-store';

@Directive({
  selector: `[ccaSequenceSideSummary]`,
})
export class SequenceSideSummaryDirective implements OnDestroy, OnInit {
  private readonly store = inject<SequenceStore>(SequenceStore);
  private readonly injector = inject(Injector);
  private readonly viewRef = inject(ViewContainerRef);

  readonly type = input.required<Type<unknown> | null>({
    alias: 'ccaSequenceSideSummary',
  });
  componentRef: ComponentRef<unknown> | null = null;

  constructor() {
    effect(() => {
      const summaryValues = this.store.summaryValues();
      untracked(() => {
        this.setSummaryValues(summaryValues);
      });
    });

    effect(() => {
      const completedSteps = this.store.completedSteps();
      untracked(() => {
        this.setCompletedSteps(completedSteps);
      });
    });
  }

  ngOnInit(): void {
    const type = this.type();
    if (type) {
      this.viewRef.clear();
      this.componentRef = this.viewRef.createComponent(type, {
        injector: this.injector,
      });

      this.setSummaryValues(this.store.summaryValues());
    }
  }

  setSummaryValues(steps: SequenceSummaryValue[]) {
    if (this.componentRef) {
      const reflectionType = reflectComponentType(
        this.componentRef.componentType,
      );

      if (
        reflectionType?.inputs?.find((x) => x.templateName === 'summaryValues')
      ) {
        this.componentRef?.setInput('summaryValues', steps);
      } else if (isDevMode()) {
        console.warn(
          `summary component does not have a input of summaryValues`,
        );
      }
    }
  }

  setCompletedSteps(steps: CCASequenceStep[]) {
    if (this.componentRef) {
      const reflectionType = reflectComponentType(
        this.componentRef.componentType,
      );

      if (
        reflectionType?.inputs?.find((x) => x.templateName === 'completedSteps')
      ) {
        this.componentRef?.setInput('completedSteps', steps);
      } else if (isDevMode()) {
        console.warn(
          `summary component does not have a input of completedSteps`,
        );
      }
    }
  }

  ngOnDestroy(): void {
    this.viewRef.clear();
    this.componentRef?.destroy();
    this.componentRef = null;
  }
}
