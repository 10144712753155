import {
  ChangeDetectorRef,
  ComponentRef,
  Directive,
  Injector,
  OnDestroy,
  TemplateRef,
  Type,
  ViewContainerRef,
  effect,
  inject,
  isDevMode,
  reflectComponentType,
  untracked,
  input,
} from '@angular/core';
import { provideTranslocoScope } from '@jsverse/transloco';
import { SequenceErrorComponent } from '../components/error';
import { SequenceLoadingComponent } from '../components/loading';
import { CCASequenceStep, SequenceSummaryValue } from '../sequence';
import { SequenceError, SequenceStore } from '../sequence-store';
import { SequenceComponentResolverFn } from '../sequence-component-resolver';
import { RouterFacade } from '@cca-common/cdk';
import { ActivatedRoute } from '@angular/router';
import { ErrorLogService } from '@cca-infra/event-management/v1';
import { sequenceNameToken } from '../sequence-name';
import { MetaData } from '../default-meta';

@Directive({
  selector: `[ccaSequenceHost]`,
  providers: [provideTranslocoScope('sequence')],
})
export class SequenceHostDirective implements OnDestroy {
  private readonly store = inject(SequenceStore);
  private readonly sequenceName = inject(sequenceNameToken);
  private readonly injector = inject(Injector);
  private readonly viewRef = inject(ViewContainerRef);
  private readonly cdr = inject(ChangeDetectorRef);
  private readonly router = inject(RouterFacade);
  private readonly route = inject(ActivatedRoute);
  private readonly errorLogService = inject(ErrorLogService);

  readonly loadingTemplate = input<TemplateRef<unknown> | null>(null, {
    alias: 'ccaSequenceHostLoadingTemplate',
  });

  readonly sequenceComponentResolver =
    input.required<SequenceComponentResolverFn>({ alias: 'ccaSequenceHost' });
  componentRef: ComponentRef<unknown> | null = null;

  constructor() {
    this.renderLoadingComponent();
    effect(() => {
      const loading = this.store.loading();
      const error = this.store.error();
      const activeStep = this.store.currentActiveStep();

      untracked(() => {
        if (loading) {
          return this.renderLoadingComponent();
        }

        if (error) {
          return this.renderErrorComponent(error);
        }

        return this.renderComponentStep(activeStep);
      });
    });
  }

  async renderComponentStep(step: CCASequenceStep | undefined | null) {
    try {
      if (!step) {
        this.renderLoadingComponent();
        return;
      }
      const ResolvedComponentType =
        await this.sequenceComponentResolver()(step);

      // if we have a resolvedComponent
      if (ResolvedComponentType) {
        const stepTitle =
          step.metaData.find((meta) => meta.identifier === MetaData.Title)
            ?.value ?? null;

        if (stepTitle) {
          // convert FooBar FOO_BAR fooBar Foo-Bar etc into 'foo-bar'
          const urlTitle = stepTitle
            .replace(/([a-z\d])([A-Z])/g, '$1_$2')
            .toLowerCase()
            .replace(/(?!^[_])[ _]/g, '-');

          this.router.navigate([urlTitle], {
            relativeTo: this.route.parent,
            replaceUrl: true,
            queryParamsHandling: 'preserve',
          });
        } else {
          this.errorLogService
            .reportError({
              message: `sequence[${this.sequenceName()}] received a invalid title '${stepTitle}' for step[${step.stepKey}]`,
              stack: null,
            })
            .subscribe();
        }

        this.renderComponent(ResolvedComponentType, step);
        return;
      }

      throw Error(
        `Could not resolve a sequenceStepComponent for "${step.stepKey}"`,
      );
    } catch (e) {
      this.renderErrorComponent();
      throw e;
    }
  }

  renderLoadingComponent() {
    this.viewRef.clear();
    this.componentRef = this.viewRef?.createComponent(SequenceLoadingComponent);
    this.cdr.markForCheck();
  }

  renderComponent(component: Type<unknown>, step: CCASequenceStep) {
    this.viewRef.clear();
    this.componentRef = this.viewRef?.createComponent(component, {
      injector: this.injector,
    });

    this.setActiveStep(step);
    this.setCompletedSteps(this.store.completedSteps());
    this.setSummaryValues(this.store.summaryValues());
    this.cdr.markForCheck();
  }

  private setCompletedSteps(steps: CCASequenceStep[]) {
    if (this.componentRef) {
      const reflectionType = reflectComponentType(
        this.componentRef.componentType,
      );
      if (
        reflectionType?.inputs?.find((x) => x.templateName === 'completedSteps')
      ) {
        this.componentRef?.setInput('completedSteps', steps);
      }
    }
  }

  private setSummaryValues(steps: SequenceSummaryValue[]) {
    if (this.componentRef) {
      const reflectionType = reflectComponentType(
        this.componentRef.componentType,
      );
      if (
        reflectionType?.inputs?.find((x) => x.templateName === 'summaryValues')
      ) {
        this.componentRef?.setInput('summaryValues', steps);
      }
    }
  }

  private setActiveStep(step: CCASequenceStep) {
    if (this.componentRef) {
      // check if the component we're rendering, has a @Input() with name activeStep
      const reflectionType = reflectComponentType(
        this.componentRef.componentType,
      );
      if (
        reflectionType?.inputs?.find((x) => x.templateName === 'activeStep')
      ) {
        this.componentRef?.setInput('activeStep', step);
      } else if (isDevMode()) {
        console.warn(
          `stepComponent is missing a 'activeStep' input, this might be intended and if so you can safely ignore this warning`,
        );
      }
    }
  }

  renderErrorComponent(errorMessage?: SequenceError) {
    this.viewRef.clear();
    this.componentRef = this.viewRef.createComponent(SequenceErrorComponent);
    if (errorMessage) {
      this.componentRef.setInput('errorMessage', errorMessage);
    }
    this.cdr.markForCheck();
  }

  ngOnDestroy(): void {
    this.viewRef.clear();
    this.componentRef?.destroy();
    this.componentRef = null;
  }
}
