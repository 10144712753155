import {
  ChangeDetectionStrategy,
  Component,
  output,
  input,
} from '@angular/core';
import { CCASequenceStep } from '../../sequence';
import { provideTranslocoScope, TranslocoModule } from '@jsverse/transloco';
import { SequenceDevToolComponent } from '../dev-tool/dev-tool.component';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { StepViewModel } from '@cca-infra/sequence-management/v1';

@Component({
  selector: 'cca-sequence-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [provideTranslocoScope('sequence')],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    TranslocoModule,
    MatButtonModule,
    MatMenuModule,
    SequenceDevToolComponent,
  ],
})
export class SequenceHeaderComponent {
  readonly currentStep = input<CCASequenceStep | null>(null);
  readonly repeatableSteps = input<StepViewModel[] | null>(null);
  readonly canGoBack = input(false);
  readonly isOnSummaryStep = input(false);
  readonly showContinueButton = input(true);
  readonly showBackButton = input(true);
  readonly title = input.required<string | null>();
  readonly description = input.required<string | null>();
  readonly continueDisabled = input.required<boolean>();
  readonly repeatStepButtonText = input.required<string>();
  readonly finishButtonLabel = input('');
  readonly showDevTools = input(false);
  readonly hideHeaderDescription = input(false);

  readonly backToPreviousStep = output<void>();
  readonly continueToNextStep = output<void>();
  readonly repeatStep = output<void>();

  get hasRepeatableSteps(): boolean {
    return !!this.repeatableSteps()?.length;
  }

  protected continueOrFinish() {
    if (this.hasRepeatableSteps) this.repeatStep.emit();
    else this.continueToNextStep.emit();
  }
}
