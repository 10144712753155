import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  output,
  input,
} from '@angular/core';
import { IconComponent } from '@cca/ui';
import { CdkDatePipe } from '@cca-common/cdk';
import { provideTranslocoScope } from '@jsverse/transloco';
import { NotificationViewModel } from '@cca-infra/notification-service/v1';

@Component({
  selector: 'cca-notification',
  imports: [IconComponent, CdkDatePipe],
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [provideTranslocoScope('notifications')],
})
export class NotificationComponent {
  @HostBinding('class')
  classes = 'block border-neutral-default rounded-xl surface-neutral-default';

  readonly notification = input.required<NotificationViewModel>();

  readonly closeNotification = output<NotificationViewModel>();

  readonly openNotification = output<NotificationViewModel>();

  onClose(event: MouseEvent) {
    event.stopPropagation();
    this.closeNotification.emit(this.notification());
  }

  onClick() {
    this.openNotification.emit(this.notification());
  }
}
